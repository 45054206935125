@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-title{
  @apply text-[34px] lg:text-[46px] text-center md:text-left mt-4 font-bold leading-none
}

.text-subtitle{
  @apply text-[16px] lg:text-[21px] text-center md:text-justify mt-2 leading-tight 
}

.arrows {
  @apply group-hover:block absolute top-[52%] -translate-x-0 -translate-y-[50%] text-2xl rounded-full p-2 bg-iconsMiller text-white cursor-pointer transition-all hover:scale-110;
}

:root {
  --primary: #8dc5eb;
  --secondary: #274584;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  /* border-radius: 5px; */
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 8px;
  border: 1px solid var(--primary);
}

div > .rc-anchor-checkbox-label{
  @apply hidden
}